import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { AsyncPipe, DatePipe, DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router, RouterLink } from '@angular/router';
import { AuthenticationService, LayoutService, RouterService, StorageService } from '@scpc/modules/common/services';
import {
  AllEvents,
  Event,
  EventCategory,
  EventSport,
  EventTopCategory,
  EventTournament,
  EventType,
  FullSport,
  EventMeta,
  ShortSport,
} from '@scpc/modules/sports/dto';
import { SportsService } from '@scpc/modules/sports/services/sports.service';
import { CmsService } from '@scpc/modules/common/services/cms.service';
import { ConfigService } from '@scpc/modules/common/services/config.service';
import { normalizeRange } from '@scpc/modules/sports/utils/range';
import { coerceArray } from '@angular/cdk/coercion';
import { BehaviorSubject, forkJoin, fromEvent, Observable, of, Subject, Subscription, switchMap } from 'rxjs';
import { LobbyItem } from '@scpc/dto/lobby';
import { MenuComponent } from '@scpc/modules/sports/components/menu/menu.component';
import { SortedMap } from 'sweet-collections';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { FilterComponent } from '@scpc/modules/sports/components/filter/filter.component';
import { EventsComponent } from '@scpc/modules/sports/components/events/events.component';
import { FilterMobileComponent } from '@scpc/modules/sports/components/filter-mobile/filter-mobile.component';
import { BannerComponent } from '@scpc/modules/common/components/banners/components/banner/banner.component';
import { LobbyItemComponent } from '@scpc/modules/lobby/components/lobby-item/lobby-item.component';
import { PageData, PageResolver } from '@scpc/modules/common/resolvers/page.resolver';
import { NoResultsComponent } from '@scpc/modules/common/components/no-results/no-results.component';
import { InViewDirective } from '@scpc/modules/in-viewport';
import { EventsSkeletonComponent } from '@scpc/modules/sports/components/events-skeleton/events-skeleton.component';
import { EventComponent } from '@scpc/modules/sports/components/event/event.component';
import { CdkAccordion, CdkAccordionItem } from '@angular/cdk/accordion';
import { SportImagePipe } from '@scpc/modules/sports/pipes/sport-image';
import { FooterComponent } from '@scpc/modules/common/components/footer/footer.component';
import { SeoComponent } from '@scpc/modules/common/components/seo/seo.component';
import { SportsWebsocketService } from '@scpc/modules/sports/services/sports.websocket.service';
import { createEventMeta, getTournamentSchema, isEventEnded, toObservable } from '@scpc/modules/sports/utils/event';
import {
  BreadcrumbsComponent,
} from '@scpc/modules/common/components/breadcrumbs/breadcrumbs.component';
import {
  eventToBreadcrumbItems,
  getAllLiveDefaultBreadcrumbs,
  getDefaultBreadcrumbs,
  sportToBreadcrumbItem,
  tournamentToBreadcrumbItems,
} from '@scpc/modules/sports/utils/breadcrumbs';
import { BreadcrumbItem } from '@scpc/modules/common/components/breadcrumbs/breadcrumbs';
import { VisibilityDirective } from '@scpc/modules/common/directives/visibility.directive';
import { TranslateModule } from '@ngx-translate/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SearchComponent } from '@scpc/modules/sports/components/search/search.component';
import { NgxJsonLdModule } from '@ngx-lite/json-ld';

@Component({
  selector: 'scp-sports-lobby',
  templateUrl: './sports-lobby.component.html',
  styleUrls: ['./sports-lobby.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    RouterLink,
    VisibilityDirective,
    FilterComponent,
    FilterMobileComponent,
    LobbyItemComponent,
    BannerComponent,
    MenuComponent,
    EventsComponent,
    EventsSkeletonComponent,
    NoResultsComponent,
    InViewDirective,
    EventComponent,
    CdkAccordion,
    CdkAccordionItem,
    SportImagePipe,
    FooterComponent,
    SeoComponent,
    BreadcrumbsComponent,
    TranslateModule,
    SearchComponent,
    NgxJsonLdModule,
  ],
})
export class SportsLobbyComponent implements OnInit, AfterContentInit, OnDestroy {

  @ViewChildren('accordionItem')
  private sportsAccordionItems: QueryList<CdkAccordionItem>;

  @ViewChildren('comp')
  private eventsComponents: QueryList<EventsComponent>;

  @ViewChild(EventComponent, { static: false })
  private eventComponent: EventComponent;

  @ViewChild(MenuComponent, { static: false })
  private menuComponent: MenuComponent;

  @ViewChild(FilterComponent, { static: false })
  private filterComponent: FilterComponent;

  @ViewChild(FilterMobileComponent, { static: false })
  private filterMobileComponent: FilterMobileComponent;

  protected empty = false;
  protected loading = false;
  protected loadingTournaments = false;
  protected isAuthorized: boolean;
  protected scroll: HTMLDivElement;
  protected trigger: Subject<boolean> = new BehaviorSubject(false);
  protected type: EventType;
  protected category: string;
  protected range: number;
  protected slug: string;
  protected tournaments: string[] = [];
  protected tournamentsState: string[] = [];
  protected scrollToTournament: string;
  protected eventState: string;
  protected categoryState = '';
  protected event: Event;
  protected eventEnded = false;
  protected hasLive: boolean;
  protected isSticky: boolean;
  protected isUp: boolean;
  protected scrollTop: number;
  protected isBrowser = isPlatformBrowser(inject(PLATFORM_ID));

  protected items: LobbyItem[];
  protected sport: EventSport;
  protected defaultSport: EventSport;
  protected sports: EventSport[] = [];
  protected tournamentsCache: Map<string, {
    category: EventCategory,
    tournament: EventTournament,
    sport: EventSport
  }> = new Map();
  protected filterTopCategories: EventTopCategory[] = [];
  protected filterCategories: EventCategory[] = [];
  protected filterSports: EventSport[];
  protected eventsBySport: SortedMap<EventSport, [EventTournament, {
    eventMeta: EventMeta,
    event?: Event
  }[]][]> = new SortedMap((a: EventSport, b: EventSport) => b.priority - a.priority);
  protected noResultsText: string;
  protected noResultsURL: string;
  protected seoText: string;

  protected loadingTournamentsSet: Set<string> = new Set();

  protected source: string;
  protected breadcrumbs: BreadcrumbItem[] = [];
  protected schema: object | null;
  protected top: boolean;

  private sportSubscription: Subscription | null;
  private allSubscription: Subscription | null;

  private datePipe: DatePipe = new DatePipe('en', null, {
    dateFormat: 'd MMMM yyyy',
    timezone: 'UTC+2',
  });

  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(protected readonly storageService: StorageService,
              private readonly sportsService: SportsService,
              private readonly sportsWebsocketService: SportsWebsocketService,
              private readonly configService: ConfigService,
              private readonly layoutService: LayoutService,
              private readonly cmsService: CmsService,
              private readonly activatedRoute: ActivatedRoute,
              private readonly authenticationService: AuthenticationService,
              private readonly changeDetectorRef: ChangeDetectorRef,
              private readonly router: Router,
              private readonly zone: NgZone,
              private readonly pageResolver: PageResolver,
              private readonly routerService: RouterService,
              @Inject(DOCUMENT) private readonly document: Document,
              @Inject('HOST') private readonly host: string,
  ) {
  }

  public ngOnInit(): void {
    this.scroll = this.document.getElementsByClassName('scp-scrollbar-content')[0] as HTMLDivElement;
    /* istanbul ignore if */
    if (this.scroll) {
      fromEvent(this.scroll, 'scroll')
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          debounceTime(15),
          map((e): number => (e.target as Element).scrollTop),
          distinctUntilChanged(),
        )
        .subscribe((scrollTop: number): void => {
          const isUp: boolean = scrollTop < this.scrollTop;
          this.scrollTop = scrollTop;
          if (this.isUp !== isUp) {
            this.isUp = isUp;
            this.changeDetectorRef.markForCheck();
          }
        });
    }
    this.authenticationService.isAuthorized().pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: boolean) => this.updateAuthorization(value));
    this.authenticationService.authorization.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: boolean) => this.updateAuthorization(value));
    this.router.events
      .pipe(takeUntilDestroyed(this.destroyRef), filter(/* istanbul ignore next */event => event instanceof NavigationEnd))
      .subscribe(/* istanbul ignore next */ async () => this.fetchDataForLobby(!this.sports?.length));
    this.fetchDataForLobby(true);
  }

  public ngAfterContentInit(): void {
    this.sportsWebsocketService.onConnect().pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.subscribe());
    if (this.sportsWebsocketService.isConnected()) {
      this.subscribe();
    } else {
      this.sportsWebsocketService.connect();
    }
  }

  public async ngOnDestroy(): Promise<void> {
    this.sportsWebsocketService.off('sports_counters', this.processCountersUpdates);
    this.sportsWebsocketService.off('top_event', this.processTopEventsUpdates);
    this.sportsWebsocketService.off('delete_top_event', this.processDeleteTopEventsUpdates);
    this.sportsWebsocketService.off('live_event', this.processLiveEventsUpdates);
    this.sportsWebsocketService.off('live_event_tournament', this.processLiveEventsByTournamentUpdates);
    this.sportsWebsocketService.off('live_event_sport', this.processLiveEventsBySportUpdates);
    this.sportsWebsocketService.off('prematch_event_tournament', this.processPreMatchEventsByTournamentUpdates);
    this.sportsWebsocketService.off('prematch_event_tournament_hour', this.processPreMatchEventsByTournamentAndRangeUpdates);
    this.sportsWebsocketService.off('full_event', this.processEventUpdates);
    this.sportsWebsocketService.off('tournaments', this.processTournamentsUpdates);
    this.sportsWebsocketService.disconnect();
  }

  protected inView(event: { status: boolean }, sport: EventSport, comp: EventsComponent): void {
    if (event.status) {
      this.preFetchDataForSportTournaments(sport, comp);
    }
  }

  protected expandSport(item: CdkAccordionItem): void {
    item.toggle();
    this.changeDetectorRef.detectChanges();
    this.trigger.next(true);
  }

  protected async updateSelectedTournaments(tournaments: string[], scroll: boolean): Promise<void> {
    if (!this.isBrowser || (!this.tournaments.length && !tournaments.length)) {
      return;
    }

    if (scroll) {
      this.scrollToTournament = tournaments.filter(x => !this.tournaments.includes(x))[0];
    }

    if (tournaments.length === 1 && this.category && this.category !== 'live') {
      await this.navigateTo(`/sports/${this.type}/${this.category}/${this.tournamentsCache.get(tournaments[0]).tournament.slug}`);
    } else {
      await this.navigateTo(this.category && this.category !== 'live' && !(isEventEnded(this.event)) ? `/sports/${this.type}/${this.category}` : this.top ? `/sports` : `/sports/live`, tournaments);
    }
  }

  protected async back(): Promise<void> {
    this.eventState = this.event?.id;
    if (this.routerService.previousUrl === '/') {
      await this.router.navigate(['/']);
      this.zone.runOutsideAngular(() => setTimeout(() => this.scrollToEvent(), 25));
    } else {
      const category: string = this.categoryState === '' ? this.categoryState : this.category;
      if (this.tournamentsState?.length === 1 && category && category !== 'live') {
        await this.navigateTo(`/sports/${this.type}/${category}/${this.tournamentsCache.get(this.tournamentsState[0]).tournament.slug}`);
      } else {
        await this.navigateTo(category && this.type ? category ? `/sports/${this.type}/${category}` : `/sports/${this.type}` : '/sports', this.tournamentsState);
      }
    }
  }

  protected trackByTournament(tournament: [EventTournament, {
    eventMeta: EventMeta,
    event?: Event
  }[]]): string {
    return tournament[0].id + tournament[0].priority;
  }

  private async navigateTo(path: string, tournament: string[] | null = null): Promise<void> {
    await this.router.navigate([path], {
      state: { tournaments: true },
      queryParams: { tournament, range: this.range > 0 ? this.range : undefined },
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute,
    });
  }

  private subscribe(): void {
    this.sportsWebsocketService.on('sports_counters', this.processCountersUpdates);
    this.sportsWebsocketService.on('live_event', this.processLiveEventsUpdates);
    this.sportsWebsocketService.on('top_event', this.processTopEventsUpdates);
    this.sportsWebsocketService.on('delete_top_event', this.processDeleteTopEventsUpdates);
    this.sportsWebsocketService.on('live_event_tournament', this.processLiveEventsByTournamentUpdates);
    this.sportsWebsocketService.on('live_event_sport', this.processLiveEventsBySportUpdates);
    this.sportsWebsocketService.on('prematch_event_tournament', this.processPreMatchEventsByTournamentUpdates);
    this.sportsWebsocketService.on('prematch_event_tournament_hour', this.processPreMatchEventsByTournamentAndRangeUpdates);
    this.sportsWebsocketService.on('full_event', this.processEventUpdates);
    this.sportsWebsocketService.on('tournaments', this.processTournamentsUpdates);
    this.sportsWebsocketService.subscribe('l_c');
    this.sportsWebsocketService.subscribe('t_prior');
  }

  private preFetchDataForSportTournaments(sport: EventTournament, comp: EventsComponent): void {
    const element = (comp.elementRef.nativeElement as HTMLElement);
    if (!element.classList.contains('loaded') && !element.classList.contains('loading')) {
      const ids: string[] = comp.items.filter(i => !i.event).map(i => i.eventMeta.id);
      if (ids.length) {
        element.classList.add('loading');
        this.sportsService.getEvents(ids)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe((events: Event[]): void => {
            const items = this.eventsBySport.get(sport)?.find(t => t[0].id === comp.elementRef.nativeElement.id);
            if (items) {
              const eventsMap: Map<string, Event> = new Map(events.map(e => [e.id, e]));
              for (const row of items[1]) {
                if (!row.event) {
                  row.event = eventsMap.get(row.eventMeta.id);
                }
              }
              element.classList.add('loaded');
              element.classList.remove('loading');
              comp.changeDetectorRef.detectChanges();
            }
          });
      }
    }
  }

  private fetchDataForLobby(first: boolean = false): void {
    if (first) {
      this.filterTopCategories = [];
      this.filterCategories = [];
      this.filterSports = [];
    }
    const { snapshot } = this.activatedRoute;
    const type = snapshot.params.type;
    const category = snapshot.params.category || '';
    const range = normalizeRange(snapshot.queryParams.range);
    const tournament = this.tournamentsCache.get(snapshot.params.tournament)?.tournament.id || snapshot.params.tournament;
    const tournaments = coerceArray(tournament || snapshot.queryParams.tournament).filter(t => !!t);
    const slug = snapshot.params.slug || '';
    const reloadFiltersTree = this.type !== type || this.category !== category || this.range !== range || (!!this.slug && !this.filterCategories.length);

    if (!first) {
      this.unsubscribeFromUpdates(category, slug, tournaments);
    }

    this.top = snapshot.url.length === 0;
    this.slug = slug;

    if (this.slug) {
      this.event = null;
      if (!this.router.getCurrentNavigation()?.extras.state?.filter) {
        this.tournamentsState = this.tournaments;
        this.categoryState = this.category;
      } else {
        this.filterTopCategories = [];
        this.filterCategories = [];
        this.filterSports = [];
      }
    }

    this.tournaments = tournaments;

    if (!this.slug) {
      this.type = type;
      this.category = category;
      this.range = range;
      this.event = null;
      this.tournamentsState = [];
      this.categoryState = '';
      this.hasLive = false;
    }

    if (this.slug) { // display event details
      this.tournaments = [/* istanbul ignore next */this.router.getCurrentNavigation()?.extras.state?.tournament];
      this.eventsBySport.clear();
      this.filterSports = [];
      this.scrollToTop();
      return this.fetchDataByEvent();
    } else if (reloadFiltersTree) {
      this.filterTopCategories = [];
      this.filterCategories = [];
      this.filterSports = [];
      this.eventsBySport.clear();
    } else if (this.tournaments.length === 0) {
      this.eventsBySport.clear();
      this.scrollToTop();
    } else if (this.tournaments.length !== 0 && !this.isLiveOrTop() && this.sports.length) {
      this.scrollToTopDuringChangeTournaments();
      this.updateBreadcrumbs(this.sport, this.tournaments);
      return this.fetchDataForSportTournaments(first);
    } else if (this.tournaments.length !== 0 && this.isLiveOrTop()) {
      this.scrollToTopDuringChangeTournaments();
      this.updateBreadcrumbs(this.sport, this.tournaments);
      return this.fetchDataForSportsTournaments(first);
    }

    if (!this.isLiveOrTop()) {
      this.defaultSport = null;
      this.updateSport();
      this.updateBreadcrumbs(this.sport, this.tournaments);
      this.scrollToEvents();
      this.fetchDataForSport(reloadFiltersTree);
    } else {
      this.sport = null;
      this.scrollToEvents();
      this.updateBreadcrumbs();
      this.fetchDataForAllLive();
    }
  }

  private fetchDataForAllLive(): void {
    /* istanbul ignore next */
    this.sportSubscription?.unsubscribe();
    this.allSubscription?.unsubscribe();
    this.loading = true;
    this.changeDetectorRef.markForCheck();
    this.allSubscription = forkJoin([
      !this.items ? this.cmsService.getSportsLobby() : of(this.items),
      this.getPage(),
      toObservable(this.sports, () => this.sportsService.getSports()),
      this.sportsService.getInitialEvents(this.type, undefined, this.tournaments, undefined, this.top),
      this.sportsService.getLiveSports(this.top),
    ]).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (data: [LobbyItem[], PageData[], EventSport[], AllEvents, EventSport[]]): void => {
          if (this.isLiveOrTop()) {
            this.items = data[0];
            this.sports = data[2];
            this.filterSports = data[4];
            this.defaultSport = this.sports.find(s => s.prematchCount > 0);
            this.updateFiltersLiveData();
            this.updateEvents(data[3]);
            this.empty = this.eventsBySport.size === 0;
            this.subscribeOnByAllLiveUpdates();
            this.updateBreadcrumbs();
            this.updateSEO(data[1][0]);
            this.updateNoResultsData();
            this.loading = false;
            this.changeDetectorRef.detectChanges();
            this.scrollToEvent();
          }
        },
      });
  }

  private isLiveOrTop() {
    return !this.category || this.category === 'live';
  }

  private fetchDataForSport(reloadFiltersTree: boolean): void {
    /* istanbul ignore next */
    this.sportSubscription?.unsubscribe();
    this.allSubscription?.unsubscribe();
    this.loading = true;
    this.changeDetectorRef.markForCheck();
    this.sportSubscription = forkJoin([
      !this.items ? this.cmsService.getSportsLobby() : of(this.items),
      this.getPage(),
      toObservable(this.sports, () => this.sportsService.getSports()),
      this.sportsService.getInitialEvents(this.type, this.category, this.tournaments, this.range),
      reloadFiltersTree
        ? this.sportsService.getTournaments(this.type, this.category, this.range)
        : of([this.filterTopCategories, this.filterCategories]),
    ]).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (data: [LobbyItem[], PageData[], EventSport[], AllEvents, [EventTopCategory[], EventCategory[], EventSport]]): void => {
          this.items = data[0];
          this.sports = data[2];
          this.filterSports = [];
          this.filterTopCategories = data[4][0];
          this.filterCategories = data[4][1];
          if (this.tournaments.length === 1 && data[3].tournament?.slug === this.tournaments[0]) {
            this.tournaments[0] = data[3].tournament.id;
          }
          this.updateSport();
          this.sport = this.sport || data[4][2];
          this.updateFiltersData();
          this.updateEvents(data[3]);
          this.updateBreadcrumbs(this.sport, this.tournaments);
          this.empty = !this.sport || !this.eventsBySport.get(this.sport)?.length;
          this.subscribeOnBySportUpdates();
          this.updateSEO(data[1][0]);
          this.updateNoResultsData();
        },
        complete: async (): Promise<void> => {
          this.loading = false;
          this.loadingTournaments = false;
          this.changeDetectorRef.detectChanges();
          this.scrollToEvent();
        },
      });
  }

  private fetchDataForSportTournaments(first: boolean): void {
    this.sportSubscription?.unsubscribe();
    this.allSubscription?.unsubscribe();
    const eventsByTournaments = this.eventsBySport.get(this.sport)?.filter(t => this.tournaments.includes(t[0].id)) || [];
    if (first) {
      this.loading = true;
    }
    this.loadingTournaments = !eventsByTournaments.length;
    this.eventsBySport.set(this.sport, eventsByTournaments);
    if (eventsByTournaments.length !== this.tournaments.length) {
      const ids = eventsByTournaments.map(t => t[0].id);
      const diff = this.tournaments.filter(t => !ids.includes(t));
      forkJoin([
        this.sportsService.getInitialEvents(this.type, undefined, diff, this.range, this.top),
        this.getPage(),
      ])
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (data: [AllEvents, PageData[]]): void => {
            const allEvents: AllEvents = data[0];
            if (this.tournaments.length === 1 && allEvents.tournament?.slug === this.tournaments[0]) {
              this.tournaments[0] = allEvents.tournament.id;
            }
            const events: Map<string, Event> = new Map();
            for (const fullSport of allEvents.fullSports) {
              for (const event of fullSport.events) {
                events.set(event.id, event);
              }
            }
            for (const shortSport of allEvents.shortSports) {
              for (const tournament of shortSport.tournaments) {
                eventsByTournaments.push([this.createTournament(tournament), tournament.events.map((eventMeta: EventMeta) => ({
                  eventMeta,
                  event: events.get(eventMeta.id),
                }))]);
              }
            }
            this.updateSport();
            this.updateSEO(data[1][0]);
            this.empty = !eventsByTournaments.length;
            this.loadingTournaments = false;
            this.loading = false;
            this.subscribeOnTournamentUpdates(this.tournaments);
          },
          complete: async () => {
            this.changeDetectorRef.detectChanges();
            this.scrollToSelectedTournament();
            this.scrollToEvent();
          },
        });
    } else if (this.tournaments.length === 1) {
      this.loadingTournaments = true;
      forkJoin([this.getPage()])
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (data: [PageData[]]): void => this.updateSEO(data[0][0]),
          complete: (): void => {
            this.loadingTournaments = false;
            this.changeDetectorRef.detectChanges();
            this.trigger.next(true);
            this.subscribeOnTournamentUpdates(this.tournaments);
          },
        });
    } else {
      this.changeDetectorRef.detectChanges();
      this.trigger.next(true);
      this.subscribeOnTournamentUpdates(this.tournaments);
    }
  }

  private fetchDataForSportsTournaments(first: boolean): void {
    this.sportSubscription?.unsubscribe();
    this.allSubscription?.unsubscribe();
    this.loading = first;
    const all = [];
    for (const sport of this.filterSports) {
      const eventsByTournaments = this.eventsBySport.get(sport)?.filter(t => this.tournaments.includes(t[0].id)) || [];
      all.push(...eventsByTournaments);
      this.eventsBySport.set(sport, eventsByTournaments);
    }
    for (const sport of this.eventsBySport) {
      if (!this.filterSports.find(s => sport[0].id === s.id)) {
        this.eventsBySport.delete(sport[0]);
      }
    }
    if (all.length !== this.tournaments.length) {
      const ids = all.map(t => t[0].id);
      const diff = this.tournaments.filter(t => !ids.includes(t) && !this.loadingTournamentsSet.has(t));
      if (diff.length) {
        diff.forEach(d => this.loadingTournamentsSet.add(d));
        this.sportsService.getInitialEvents(this.type, undefined, diff, this.range, this.top)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (allEvents: AllEvents) => {
              const events: Map<string, Event> = new Map();
              for (const fullSport of allEvents.fullSports) {
                for (const event of fullSport.events) {
                  events.set(event.id, event);
                }
              }
              const sportsWithChanges: Set<string> = new Set();
              for (const shortSport of allEvents.shortSports) {
                for (const tournament of shortSport.tournaments) {
                  if (this.tournaments.includes(tournament.id)) {
                    const sport = this.tournamentsCache.get(tournament.id).sport;
                    sportsWithChanges.add(sport.id);
                    const eventsByTournaments = this.eventsBySport.get(sport);
                    eventsByTournaments.push([this.createTournament(tournament), tournament.events.map((eventMeta: EventMeta) => ({
                      eventMeta,
                      event: events.get(eventMeta.id),
                    }))]);
                    this.eventsBySport.set(sport, eventsByTournaments);
                  }
                }
              }
              this.empty = false;
              this.loading = false;
              this.loadingTournaments = false;
              this.changeDetectorRef.detectChanges();
              const sportsWithEvents: string[] = [...this.eventsBySport.entries()].filter(b => b[1].length).map(b => b[0].id);
              sportsWithEvents.forEach((sport: string, index: number) => {
                if (sportsWithChanges.has(sport)) {
                  this.sportsAccordionItems.get(index).open();
                }
              });
            },
            complete: async () => {
              diff.forEach(tournamentId => this.loadingTournamentsSet.delete(tournamentId));
              this.subscribeOnTournamentUpdates(diff);
              this.scrollToSelectedTournament();
              this.scrollToEvent();
            },
          });
      }
    } else {
      this.loading = false;
      this.changeDetectorRef.detectChanges();
      this.trigger.next(true);
      this.subscribeOnTournamentUpdates(this.tournaments);
    }
  }

  private fetchDataByEvent(): void {
    /* istanbul ignore next */
    this.sportSubscription?.unsubscribe();
    this.allSubscription?.unsubscribe();
    this.loading = true;
    this.changeDetectorRef.markForCheck();
    this.sportSubscription = forkJoin([
      this.getPage(),
      this.sportsService.getEvents(undefined, this.slug, 'main', false),
      this.sportsService.getTournamentsByEvent(this.slug),
    ]).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: async (data: [PageData[], Event[], [EventTopCategory[], EventCategory[], EventSport[], number]]): Promise<void> => {
          if (!data[1][0]) {
            await this.router.navigateByUrl('/sports');
            return;
          }
          this.filterTopCategories = data[2][0];
          this.filterCategories = data[2][1];
          this.filterSports = data[2][2];
          this.event = data[1][0];
          this.sport = this.event.sport;
          this.eventEnded = isEventEnded(this.event);
          this.category = this.event.sport.slug;
          this.type = this.event.live ? 'live' : 'prematch';
          /* istanbul ignore next */
          this.range = this.range ?? 0;
          this.hasLive = data[2][3] > 0;
          if (this.tournaments.length) {
            this.tournaments = [this.event.tournament.id];
          }
          this.empty = false;
          this.sportsWebsocketService.subscribe('f_e_' + this.event.id, true);
          this.updateSEO(data[0][0]);
          this.updateFiltersData();
          this.updateBreadcrumbs();
          this.updateNoResultsData();
        },
        complete: () => {
          this.loading = false;
          this.changeDetectorRef.markForCheck();
        },
      });
  }

  private createTournament(tournament: EventTournament): EventTournament {
    return {
      id: tournament.id,
      name: tournament.name,
      priority: tournament.priority,
    };
  }

  private updateEvents(allEvents: AllEvents): void {
    const cache: Map<string, { full?: FullSport, short: ShortSport }> = new Map();
    for (const sport of allEvents.shortSports) {
      cache.set(sport.slug, { full: allEvents.fullSports.find(s => s.slug === sport.slug), short: sport });
    }
    for (const sport of this.sports) {
      if (cache.has(sport.slug)) {
        const eventsByTournament: [EventTournament, { eventMeta: EventMeta, event?: Event }[]][] = [];
        const events: Map<string, Event> = new Map((cache.get(sport.slug).full?.events || []).map(event => [event.id, event]));
        for (const item of (cache.get(sport.slug).short.tournaments || /* istanbul ignore next */ [])) {
          eventsByTournament.push([{
            id: item.id,
            name: item.name,
            priority: item.priority,
          }, item.events.map((eventMeta: EventMeta) => ({ eventMeta, event: events.get(eventMeta.id) }))]);
        }
        this.eventsBySport.set(sport, eventsByTournament);
      }
    }

    const tournament: EventTournament | undefined = allEvents.tournament;
    if (allEvents.category && tournament && !(this.tournamentsCache.has(tournament.id) && this.tournamentsCache.has(tournament.slug))) {
      const data = { category: allEvents.category, tournament, sport: allEvents.sport };
      this.tournamentsCache.set(tournament.id, data);
      this.tournamentsCache.set(tournament.slug, data);
    }
  }

  private updateNoResultsData(): void {
    if (!this.isLiveOrTop()) {
      this.noResultsText = this.range ? 'Go to prematch' : 'Go to all live';
      this.noResultsURL = this.range ? `/sports/prematch/${this.category}` : '/sports/live';
    } else if (this.defaultSport) {
      this.noResultsText = `Go to ${this.defaultSport.name.toLowerCase()} prematch`;
      this.noResultsURL = `/sports/prematch/${this.defaultSport.slug}`;
    } else {
      this.noResultsText = 'Go to soccer prematch';
      this.noResultsURL = '/sports/prematch/soccer';
    }
  }

  private updateSEO(page?: PageData): void {
    this.layoutService.setPageData({
      ...this.normalizeMeta(page?.title, page?.description),
      seoText: null,
      banners: [],
    });
    this.seoText = page?.seoText;
  }

  private normalizeMeta(title?: string, description?: string): {
    title: string,
    description: string
  } {
    title = (title ?? this.configService.defaultPageTitle) || '';
    description = (description ?? this.configService.defaultPageDescription) || '';
    if (this.event) {
      const sport: string = this.event.sport.name.toLowerCase();
      const category: string = this.event.category.name;
      const tournament: string = this.event.tournament.name;
      const eventName: string = this.event.season
        ? this.event.season.name
        : `${this.event.homeTeam.name} - ${this.event.awayTeam.name}`;
      const eventDate: string = this.datePipe.transform(this.event.scheduleTime);
      title = title
        .replace('{event_name}', eventName)
        .replace('{event_date}', eventDate)
        .replace('{sport_name}', sport)
        .replace('{category}', category)
        .replace('{tournament}', tournament);
      description = description
        .replace('{event_name}', eventName)
        .replace('{event_date}', eventDate)
        .replace('{sport_name}', sport)
        .replace('{category}', category)
        .replace('{tournament}', tournament);
    } else if (this.category && this.tournaments?.length === 1) {
      const data = this.tournamentsCache.get(this.tournaments[0]);
      if (data) {
        const { category, tournament, sport } = data;
        title = title
          .replace('{sport_name}', sport.name)
          .replace('{category}', category.name)
          .replace('{tournament}', tournament.name);
        description = description
          .replace('{sport_name}', sport.name)
          .replace('{category}', category.name)
          .replace('{tournament}', tournament.name);
      } else {
        return { title: this.configService.defaultPageTitle, description: this.configService.defaultPageDescription };
      }
    }
    return { title, description };
  }

  private updateAuthorization(value: boolean): void {
    this.isAuthorized = value;
    this.changeDetectorRef.markForCheck();
  }

  private scrollToTop(): void {
    /* istanbul ignore next */
    if (this.scroll?.scrollTo) {
      this.scroll.scrollTo({ top: 0 });
    }
  }

  private scrollToEvents(): void {
    /* istanbul ignore next */
    if (this.isSticky && this.scroll?.scrollTo) {
      const el: Element = this.document.getElementsByClassName('scp-sports-menu-sticky')[0];
      if (el) {
        const element: HTMLElement = this.document.getElementsByClassName('fixed')[0] as HTMLElement;
        const top: number = parseInt(getComputedStyle(el).top.replace('px', ''), 10);
        this.scroll.scrollTo({ behavior: 'auto', top: element.offsetTop - top });
      } else {
        this.scrollToTop();
      }
    }
  }

  private scrollToTopDuringChangeTournaments(): void {
    if (this.tournaments.length === 1) {
      this.scrollToTop();
    }
  }

  private scrollToSelectedTournament(): void {
    if (this.scrollToTournament) {
      this.scrollToElement(this.document.getElementById(`${this.scrollToTournament}`));
      this.scrollToTournament = null;
    }
  }

  private scrollToEvent() {
    if (this.eventState) {
      this.scrollToElement(this.document.getElementById(`${this.eventState}`));
      this.eventState = null;
    }
  }

  private scrollToElement(element: HTMLElement): void {
    if (element && this.scroll?.scrollTo) {
      const el: Element = this.document.getElementsByClassName('scp-sports-menu-sticky')[0];
      const top: number = parseInt(getComputedStyle(el).top.replace('px', ''), 10);
      this.scroll.scrollTo({
        behavior: 'smooth',
        top: element.offsetTop - (this.isUp && this.isSticky ? el.scrollHeight : 0) - top,
      });
    }
  }

  private subscribeOnByAllLiveUpdates(): void {
    if (this.tournaments.length) {
      this.subscribeOnTournamentUpdates(this.tournaments);
    } else {
      if (this.top) {
        this.sportsWebsocketService.subscribe('t_e_all');
      } else {
        this.sportsWebsocketService.subscribe('l_s_all');
      }
    }
  }

  private subscribeOnBySportUpdates(): void {
    if (this.tournaments.length) {
      this.subscribeOnTournamentUpdates(this.tournaments);
    } else {
      if (this.filterTopCategories.length) {
        const ids: string[] = this.filterTopCategories.map((c: EventTopCategory): string => c.tournament.id);
        this.subscribeOnTournamentUpdates(ids);
      } else {
        if (this.type === 'live') {
          if (this.sport) {
            this.sportsWebsocketService.subscribe('l_s_' + this.sport.id);
          }
        } else {
          const ids: string[] = this.filterCategories.flatMap((c: EventCategory): string[] => c.tournaments.map((t: EventTournament): string => t.id));
          this.subscribeOnTournamentUpdates(ids);
        }
      }
    }
  }

  private subscribeOnTournamentUpdates(ids: string[]): void {
    this.sportsWebsocketService.subscribe(ids.map(id => this.formatTournamentTopic(id)));
  }

  private unsubscribeFromUpdates(category: string, slug: string, tournaments: string[]): void {
    if (!this.category && !this.tournaments.length && (category || slug || tournaments.length)) {
      this.sportsWebsocketService.unsubscribe('t_e_all');
    } else if (this.type === 'live' && this.category === 'live' && !this.tournaments.length && (category !== 'live' || slug || tournaments.length)) {
      this.sportsWebsocketService.unsubscribe('l_s_all');
    } else if (this.slug && this.event) {
      this.sportsWebsocketService.unsubscribe('f_e_' + this.event.id);
    } else if (this.tournaments.length) {
      this.unsubscribeFromTournamentUpdates(this.tournaments.filter(t => !tournaments.includes(t)));
    } else {
      if (this.filterTopCategories.length) {
        this.unsubscribeFromTournamentUpdates(this.filterTopCategories.map(c => c.tournament.id));
      } else {
        if (this.type === 'live') {
          if (this.sport) {
            this.sportsWebsocketService.unsubscribe('l_s_' + this.sport.id);
          }
        } else {
          this.unsubscribeFromTournamentUpdates(this.filterCategories.flatMap(c => c.tournaments.map(t => t.id)));
        }
      }
    }
  }

  private unsubscribeFromTournamentUpdates(ids: string[]): void {
    this.sportsWebsocketService.unsubscribe(ids.filter(id => !!id).map(id => this.formatTournamentTopic(id)));
  }

  private formatTournamentTopic(id: string): string {
    if (this.top) {
      return `t_t_${id}${this.range ? '_h_' + this.range : ''}`;
    }
    return `${this.type === 'prematch' ? 'p' : 'l'}_t_${id}${this.range ? '_h_' + this.range : ''}`;
  }

  private processCountersUpdates = (data: string) => {
    if (!this.sports) {
      return;
    }

    const oldSports: Map<string, EventSport> = new Map(this.sports.map((sport: EventSport) => [sport.slug, sport]));

    this.sports = JSON.parse(data).map((sport: EventSport): EventSport => {
      if (sport.id === 'all_live') {
        sport.slug = 'live';
      }
      return sport;
    });

    const newSports: Map<string, EventSport> = new Map(this.sports.map((sport: EventSport) => [sport.slug, sport]));
    const oldFilterSports: Map<string, EventSport> = new Map(this.filterSports.map((sport: EventSport) => [sport.slug, sport]));
    for (const sport of newSports) {
      const old: EventSport | undefined = oldSports.get(sport[0]);
      if (old && old.priority !== sport[1].priority) {
        const data = this.eventsBySport.get(old);
        if (data) {
          for (const item of data) {
            for (const e of item[1]) {
              if (e.event) {
                e.event.sport.priority = sport[1].priority;
              }
            }
          }
        }
        this.eventsBySport.delete(old);
        this.eventsBySport.set(sport[1], data);

        const oldFilter: EventSport | undefined = oldFilterSports.get(sport[0]);
        if (oldFilter) {
          oldFilter.priority = sport[1].priority;
        }
      }
    }

    this.filterSports = this.filterSports.sort((a: EventSport, b: EventSport) => b.priority - a.priority);
    this.updateSport();
    this.zone.run((): void => /* istanbul ignore next */ {
      this.menuComponent?.update();
      this.filterComponent?.update();
      this.filterMobileComponent?.update();
    });
  };

  private processLiveEventsUpdates = (data: string): void => {
    if (this.category === 'live') {
      this.processEventsUpdates(data);
    }
  };

  private processTopEventsUpdates = (data: string): void => {
    if (!this.category) {
      this.processEventsUpdates(data);
    }
  };

  private processDeleteTopEventsUpdates = (data: string): void => {
    if (!this.category) {
      this.zone.runOutsideAngular((): void => {
        const event: Event = JSON.parse(data);
        this.deleteEvent(event);
      });
    }
  };

  private processLiveEventsBySportUpdates = (data: string): void => {
    this.processEventsUpdates(data);
  };

  private processLiveEventsByTournamentUpdates = (data: string): void => {
    this.processEventsUpdates(data);
  };

  private processPreMatchEventsByTournamentUpdates = (data: string): void => {
    this.processEventsUpdates(data);
  };

  private processPreMatchEventsByTournamentAndRangeUpdates = (data: string): void => {
    this.processEventsUpdates(data);
  };

  private processEventUpdates = (data: string): void => {
    if (this.slug) {
      this.zone.runOutsideAngular((): void => {
        const event = JSON.parse(data);
        if (this.event.id === event.id) {
          const type: EventType = this.event.live ? 'live' : 'prematch';
          const status: string = this.event.status;
          this.event = event;
          this.eventEnded = isEventEnded(this.event);
          this.eventComponent?.update();
          if (type !== this.type || (this.event.status !== status && this.eventEnded)) {
            this.type = type;
            this.updateBreadcrumbs();
            this.updateFilters(this.event, false);
          }
        }
      });
    }
  };

  private processTournamentsUpdates = (data: string): void => {
    this.zone.runOutsideAngular((): void => {
      const tournaments: { id: string, priority: number }[] = JSON.parse(data);
      const currentTournaments: Map<string, EventTournament> = new Map<string, EventTournament>(this.filterCategories
        .flatMap((category: EventCategory) => category.tournaments)
        .map((tournament: EventTournament) => [tournament.id, tournament]),
      );
      const currentTopTournaments: Map<string, EventTournament> = new Map<string, EventTournament>(this.filterTopCategories
        .map((category: EventTopCategory) => [category.tournament.id, category.tournament]),
      );
      const currentSportTournaments: Map<string, EventTournament> = new Map<string, EventTournament>(this.filterSports
        .flatMap((sport: EventSport) => sport.tournaments.map(t => [t.tournament.id, t.tournament])),
      );
      let changes: number = 0;
      for (const tournament of tournaments) {
        const id: string = tournament.id;
        const priority: number = tournament.priority;
        if (currentSportTournaments.has(id)) {
          currentSportTournaments.get(id).priority = priority;
          changes++;
        }
        if (currentTopTournaments.has(id)) {
          currentTopTournaments.get(id).priority = priority;
          changes++;
        }
        if (currentTournaments.has(id)) {
          currentTournaments.get(id).priority = priority;
          changes++;
        }
        if (this.tournamentsCache.has(id)) {
          this.tournamentsCache.get(id).tournament.priority = priority;
        }
        this.eventsBySport.forEach((sport: [EventTournament, {
          eventMeta: EventMeta,
          event?: Event
        }[]][]): void => {
          for (const t of sport) {
            if (t[0].id === id) {
              t[0].priority = priority;
              t[1].filter(i => !!i.event).forEach(i => i.event.tournament.priority = priority);
              break;
            }
          }
        });
      }
      if (changes > 0) {
        this.eventsBySport.forEach((sport: [EventTournament, {
          eventMeta: EventMeta,
          event?: Event
        }[]][]): void => {
          sport.sort((a, b) => this.compareTournaments(a[0], b[0]));
        });
        this.filterTopCategories.sort((a: EventTopCategory, b: EventTopCategory): number => {
          const diff: number = b.tournament.priority - a.tournament.priority;
          if (diff !== 0) {
            return diff;
          }
          return (a.category.name + '. ' + a.tournament.name).localeCompare((b.category.name + '. ' + b.tournament.name));
        });
        this.filterCategories.forEach((category: EventCategory): void => {
          category.tournaments.sort((a: EventTournament, b: EventTournament) => this.compareTournaments(a, b));
        });
        this.filterSports.forEach((sport: EventSport): void => {
          sport.tournaments.sort((
            a: { tournament: EventTournament, category: EventCategory },
            b: {
              tournament: EventTournament,
              category: EventCategory
            }) => this.compareTournaments(a.tournament, b.tournament));
        });
        this.filterComponent?.update();
        this.filterMobileComponent?.update();
        this.zone.run(() => this.changeDetectorRef.markForCheck());
      }
    });
  };

  private compareTournaments(a: EventTournament, b: EventTournament): number {
    const diff: number = b.priority - a.priority;
    if (diff !== 0) {
      return diff;
    }
    return a.name.localeCompare(b.name);
  }

  private processEventsUpdates(data: string): void {
    this.zone.runOutsideAngular((): void => {
      const event: Event = JSON.parse(data);
      if (isEventEnded(event)) {
        this.deleteEvent(event);
      } else if (!this.loading && !this.tournaments.length || this.tournaments.includes(event.tournament.id)) {
        this.addEvent(event);
      }
    });
  }

  private addEvent(event: Event): void {
    const sport = this.eventsBySport.get(event.sport);
    this.addTournamentToCache(event);
    if (sport) {
      const tournament = sport.find(p => p[0].id === event.tournament.id);
      if (tournament) {
        const currentEvent: {
          eventMeta: EventMeta;
          event?: Event;
        } | undefined = tournament[1].find(e => e.eventMeta.id === event.id);
        if (currentEvent) {
          const scheduleTime = currentEvent.eventMeta.scheduleTime;
          currentEvent.event = event;
          if (scheduleTime === event.scheduleTime) {
            /* istanbul ignore next */
            this.eventsComponents.find(e => e.tournament.id === event.tournament.id)?.updateEvent(event.id);
          } else {
            tournament[1] = tournament[1].sort(  /* istanbul ignore next */(a, b) => a.eventMeta.scheduleTime - b.eventMeta.scheduleTime);
            /* istanbul ignore next */
            this.eventsComponents.find(e => e.tournament.id === event.tournament.id)?.update();
          }
        } else {
          tournament[1].push({ eventMeta: createEventMeta(event), event });
        }
        tournament[1] = tournament[1].sort( /* istanbul ignore next */(a, b) => a.eventMeta.scheduleTime - b.eventMeta.scheduleTime);
        /* istanbul ignore next */
        this.eventsComponents.find(e => e.tournament.id === event.tournament.id)?.update();
      } else {
        this.updateFilters(event);
        sport.push([event.tournament, [{ eventMeta: createEventMeta(event), event }]]);
        sport.sort((a, b) => b[0].priority - a[0].priority);
        this.empty = false;
        this.zone.run(() => this.changeDetectorRef.markForCheck());
      }
    } else {
      this.updateFilters(event);
      this.eventsBySport.set(event.sport, [[event.tournament, [{
        eventMeta: createEventMeta(event),
        event,
      }]]]);
      this.empty = false;
      this.zone.run(() => this.changeDetectorRef.markForCheck());
    }
  }

  private deleteEvent(event: Event): void {
    const sport = this.eventsBySport.get(event.sport);
    if (sport) {
      const tournament = sport.find(p => p[0].id === event.tournament.id);
      if (tournament) {
        tournament[1] = tournament[1].filter(e => e.eventMeta.id !== event.id);
        if (!tournament[1].length) {
          const length = sport.length;
          const tournaments = sport.filter(t => t[0].id !== event.tournament.id && t[1].length);
          if (tournaments.length) {
            this.eventsBySport.set(event.sport, tournaments);
            if (length !== tournaments.length) {
              this.updateFilters(event, false);
            }
          } else {
            this.updateFilters(event, false);
            this.eventsBySport.delete(event.sport);
          }
          if (!this.isLiveOrTop()) {
            this.empty = !this.eventsBySport.get(event.sport)?.length;
          } else {
            this.empty = [...this.eventsBySport.entries()].filter(v => /* istanbul ignore next */ v[1]?.length).length === 0;
          }
          this.zone.run(() => this.changeDetectorRef.markForCheck());
        } else {
          /* istanbul ignore next */
          this.eventsComponents.find((e: EventsComponent): boolean => e.tournament.id === event.tournament.id)?.update();
        }
      }
    }
  }

  private updateFilters(event: Event, isNew: boolean = true): void {
    if (isNew) {
      this.addTournamentToCache(event);
    }
    if (this.event) {
      this.sportsService.getTournamentsByEvent(this.event.slug)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((data) => {
          this.filterTopCategories = data[0];
          this.filterCategories = data[1];
          this.filterSports = data[2];
          this.updateFiltersData();
          this.zone.run(() => this.changeDetectorRef.markForCheck());
        });
    } else if (this.isLiveOrTop()) {
      this.sportsService.getLiveSports(this.top).pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((data) => {
          this.filterSports = data;
          this.updateFiltersLiveData();
          this.zone.run(() => this.changeDetectorRef.markForCheck());
        });
    } else if (this.type) {
      this.sportSubscription = this.sportsService.getTournaments(this.type, this.category, this.range)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((data) => {
          this.filterTopCategories = data[0];
          this.filterCategories = data[1];
          this.updateFiltersData();
          this.zone.run(() => this.changeDetectorRef.markForCheck());
        });
    }
  }

  private updateFiltersLiveData() {
    this.tournamentsCache.clear();
    for (const sport of this.filterSports) {
      for (const item of sport.tournaments) {
        const data = { category: item.category, tournament: item.tournament, sport };
        this.tournamentsCache.set(item.tournament.id, data);
        this.tournamentsCache.set(item.tournament.slug, data);
      }
    }
  }

  private updateFiltersData(): void {
    this.tournamentsCache.clear();
    for (const category of this.filterCategories) {
      for (const tournament of category.tournaments) {
        const data = { category, tournament, sport: this.sport };
        this.tournamentsCache.set(tournament.id, data);
        this.tournamentsCache.set(tournament.slug, data);
      }
    }
  }

  private updateSport(): void {
    /* istanbul ignore next */
    if (this.category) {
      this.sport = this.sports?.find((s: EventSport) => s.slug === this.category);
      if (!this.sport) {
        this.empty = true;
      }
    }
  }

  private addTournamentToCache(event: Event): void {
    const data = {
      category: event.category,
      tournament: event.tournament,
      sport: event.sport,
    };
    this.tournamentsCache.set(event.tournament.id, data);
    this.tournamentsCache.set(event.tournament.slug, data);
  }

  private updateBreadcrumbs(sport?: EventSport, tournaments?: string[]): void {
    if (this.event) {
      this.source = 'Event page';
      this.breadcrumbs = eventToBreadcrumbItems(this.event);
      this.schema = null;
    } else if (this.category && this.category !== 'live' && tournaments?.length === 1 && this.tournamentsCache.has(tournaments[0])) {
      const data = this.tournamentsCache.get(tournaments[0]);
      this.source = `${data.sport.name} ${this.type} tournament page`;
      this.breadcrumbs = tournamentToBreadcrumbItems(data, this.type);
      this.schema = getTournamentSchema(data, this.type, this.host);
    } else if (sport) {
      this.source = `${sport.name} ${this.type} sport page`;
      this.breadcrumbs = sportToBreadcrumbItem(sport, this.type);
      this.schema = null;
    } else if (this.top) {
      this.source = 'Top page';
      this.breadcrumbs = getDefaultBreadcrumbs();
      this.schema = null;
    } else {
      this.source = 'All live page';
      this.breadcrumbs = getAllLiveDefaultBreadcrumbs();
      this.schema = null;
    }
  }

  private getPage(): Observable<PageData[]> {
    const url: string = this.pageResolver.getPageUrl(this.activatedRoute.snapshot);
    if (this.tournaments.length === 1 && !this.isLiveOrTop()) {
      return forkJoin([
        this.cmsService.getPage(url),
        this.cmsService.getPage(url.substring(0, url.lastIndexOf('/')) + '/tournaments'),
      ]).pipe(switchMap((data: [PageData[], PageData[]]) => of([{
        title: data[0][0]?.title || data[1][0]?.title,
        description: data[0][0]?.description || data[1][0]?.description,
        seoText: data[0][0]?.seoText,
        banners: [],
      }])));
    }
    return this.cmsService.getPage(url);
  }

}
